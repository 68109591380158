import React, { Component } from 'react';
import axios from 'axios';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { LauncherForm } from './components/LauncherForm';

import './custom.css'

export default class App extends Component {
    static displayName = App.name;
    state = { isHide: false }

    componentDidMount() {
        axios.get('launcher/hide')
            .then(res => {
                const isHide = res.data;
                this.setState({ isHide });
            });
    }

    render() {
        return (
            this.state.isHide ?
                (<div></div>) :
                (<Layout><Route exact path='/' component={LauncherForm} /></Layout>))
    }
}
