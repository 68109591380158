import React, { Component } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route } from "react-router-dom";

export class LauncherForm extends Component {
  static displayName = LauncherForm.name;

  constructor(props) {
    super(props);
      this.state = { infos: [], game: '', language: '', currency: '', user: 'testx01', token: '', loading: true, operator: 'qm' };

      this.handleGameChange = this.handleGameChange.bind(this);
      this.handleLanguageChange = this.handleLanguageChange.bind(this);
      this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
      this.handleUserChange = this.handleUserChange.bind(this);
      this.handleTokenChange = this.handleTokenChange.bind(this);
      this.handleOperatorChange = this.handleOperatorChange.bind(this);
  }

  componentDidMount() {
      this.populateLauncherInfo();
  }

  renderLauncher(infos) {
    return (
        <table className="table" aria-labelledby="tabelLabel">
            <tbody>
                <tr>
                    <td><label htmlFor="operator">Operator</label></td>
                    <td>
                        <input type="radio" id="operator" name="operator" value={this.state.operator} onChange={this.handleOperatorChange} checked={this.state.operator}/> QM
                    </td>
                </tr>
                <tr>
                    <td><label htmlFor="token">Token</label></td>
                    <td>
                        <input type="text" id="token" name="token" value={this.state.token} onChange={this.handleTokenChange} />
                    </td>
                </tr>
                <tr>
                    <td><label htmlFor="games">Games</label></td>
                    <td>
                        <select name="game" id="games" value={this.state.game} onChange={this.handleGameChange} >
                            {infos.games.map(g => <option key={g} value={g}>{g}</option>)}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td><label htmlFor="language">Languages</label></td>
                    <td>
                        <select name="languages" id="languages" value={this.state.language} onChange={this.handleLanguageChange}>
                            {infos.languages.map(l => <option key={l} value={l}>{l}</option>)}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td><label htmlFor="currency">Currency</label></td>
                    <td>
                        <select name="currency" id="currencies" value={this.state.currency} onChange={this.handleCurrencyChange}>
                            {infos.currencies.map(c => <option key={c} value={c}>{c}</option>)}
                        </select>
                    </td>
                </tr>
                <tr style={{ display:"none"}}>
                    <td><label htmlFor="user">User</label></td>
                    <td>
                        <input type="text" id="user" name="user" value={this.state.user} onChange={this.handleUserChange} />
                    </td>
                </tr>
                <tr>
                    <td colSpan="2"><button onClick={() => this.handleLaunch()}>Launch</button></td>
                </tr>
            </tbody>
        </table>
    );
  }

    handleLaunch() {
        let data = {
            game_identifier: this.state.game,
            language: this.state.language,
            currency: this.state.currency,
            user: this.state.user,
            authenticity_token: this.state.token,
            operator_code: this.state.operator.toLowerCase()
        };

        axios({
            method: "POST",
            url: 'launcher',
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            window.location.href = `${response.data}`;
        })
    }

    handleOperatorChange(e) {
        this.setState({ operator: e.target.value });
    }

    handleGameChange(e) {
        this.setState({ game: e.target.value });
    }

    handleLanguageChange(e) {
        this.setState({ language: e.target.value });
    }

    handleCurrencyChange(e) {
        this.setState({ currency: e.target.value });
    }

    handleUserChange(e) {
        this.setState({ user: e.target.value });
    }

    handleTokenChange(e) {
        this.setState({ token: e.target.value });
    }

  render() {
    let contents = this.state.loading
        ? <p><em>Loading...</em></p>
        : this.renderLauncher(this.state.infos);

    return (
      <div>
        {contents}
      </div>
    );
  }

  async populateLauncherInfo() {
    const response = await fetch('launcher');
    const data = await response.json();
    this.setState({ game: data.games[0] });
    this.setState({ language: data.languages[0] });
    this.setState({ currency: data.currencies[0] });
    this.setState({ infos: data, loading: false });
    this.setState({ token: ""});
  }
}
